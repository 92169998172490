<template>
    <div class="daily-sett">
        <el-card>
            <div slot="header" class="clearfix picker-box">
                <span>逐日结算</span>
                <ExcelDownLoad class="download-btn" filename="逐日结算" api="dataCusfund" :size="1000" />
                <date-range style="float: right" v-on:change="loadData(page)"></date-range>
            </div>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="rq" label="日期" width="110"></el-table-column>
                <el-table-column prop="zjqyze" label="资金权益总额" width="110">
                </el-table-column>
                <el-table-column prop="kyzj" label="可用资金" width="110">
                </el-table-column>
                <el-table-column prop="zjbzj" label="追加保证金" width="110">
                </el-table-column>
                <el-table-column prop="fxd" label="风险度" width="110">
                </el-table-column>
                <el-table-column prop="zrsrjc" label="上日结存（逐日盯市）" width="140">
                </el-table-column>
                <el-table-column prop="zbsrjc" label="上日结存（逐笔对冲）" width="140">
                </el-table-column>
                <el-table-column prop="zrdrjc" label="当日结存（逐日盯市）" width="140">
                </el-table-column>
                <el-table-column prop="zbdrjc" label="当日结存（逐笔对冲）" width="140">
                </el-table-column>
                <el-table-column prop="zrdrzyk" label="当日总盈亏（逐日盯市）" width="160">
                </el-table-column>
                <el-table-column prop="zbdrzyk" label="当日总盈亏（逐笔对冲）" width="160">
                </el-table-column>
                <el-table-column prop="zbfdyk" label="浮动盈亏（逐笔对冲）" width="140">
                </el-table-column>
                <el-table-column prop="fhbcdje" label="非货币充抵金额" width="110">
                </el-table-column>
                <el-table-column prop="syhbzj" label="实有货币资金" width="110">
                </el-table-column>
                <el-table-column prop="hbcdje" label="货币充抵金额" width="110">
                </el-table-column>
                <el-table-column prop="drzqyj" label="当日总权利金" width="110">
                </el-table-column>
                <el-table-column prop="djzj" label="冻结资金" width="110">
                </el-table-column>
                <el-table-column prop="bzjzy" label="保证金占用" width="110">
                </el-table-column>
                <el-table-column prop="drcqhj" label="当日存取合计" width="110">
                </el-table-column>
                <el-table-column prop="sxf" label="当日手续费" width="110">
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    layout="prev, pager, next, sizes"
                    @size-change="sizeChange"
                    @current-change="loadData"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="10"
                    :total="total"
            >
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
  import DateRange from '@/components/DateRange.vue'
  import {Loading} from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";
  import ExcelDownLoad from "../../components/ExcelDownLoad";

  export default {
    name: "DailySett",
    components: {
      DateRange,
      ExcelDownLoad
    },
    data () {
      return {
        size: 10,
        page: 1,
        total: 0,
        date: "",
        tableData: [],
        value: "",
        pickerOptions: {
          disabledDate (time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [
            {
              text: "今天",
              onClick (picker) {
                picker.$emit("pick", new Date());
              },
            },
            {
              text: "昨天",
              onClick (picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                picker.$emit("pick", date);
              },
            },
            {
              text: "一周前",
              onClick (picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", date);
              },
            },
          ],
        },
      };
    },
    methods: {
      sizeChange (size) {
        this.size = size;
        this.loadData(this.page);
      },
      async loadData (page) {
        if (page) {
          this.page = page ;
        }
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
          e_date: this.$store.state.dateRange.e_date,
          page: this.page,
          size: this.size,
          v_code: ''
        }
        let loadingInstance = Loading.service({target: '.el-table'});
        let res = await apiAnalysisData('dataCusfund', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          this.total = res.data.total;
          resData.forEach(item => {
            delete item.id
            delete item.account_id
            delete item.created_at
            delete item.updated_at
          })
          this.tableData = resData
          loadingInstance.close();
        }
      },
      dateChange (picker) {
        if (picker) {
          let Y = picker.getFullYear().toString();
          let M = (picker.getMonth() + 1 < 10
              ? "0" + (picker.getMonth() + 1)
              : picker.getMonth() + 1
          ).toString();
          let D = picker.getDate().toString();
          let date = Y + "-" + M + "-" + D;
          this.date = date;
        } else {
          this.date = "";
        }
        this.loadData(1);
      },
    },
    mounted () {
      this.loadData(1);
    },
  };
</script>

<style lang="scss">
    .daily-sett {
        .picker-box {
            position: relative;

            .download-btn {
                width: fit-content;
                height: fit-content;
                border-color: #e78051;
                background: #e78051;
                color: #FFF;
                position: absolute;
                right: 45px;
                top: 0;
            }

            .date-picker {
                width: 140px;
                position: absolute;
                right: -4px;
                top: -4px;

                .el-input__inner {
                    width: 140px;
                    height: 28px;
                }

                .el-input__icon {
                    line-height: 28px;
                }
            }
        }
        .el-card {
            border: 0;

            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }
        }

        .cell {
            font-size: 12px;
        }

        .el-pagination {
            margin-top: 20px;
        }
    }
</style>
