<template>
    <div class="excel-download-component">
        <el-button class="download-btn" size="mini" icon="el-icon-download" @click="open">数据导出</el-button>
        <el-dialog title="请选择导出条件" :visible.sync="dialogFormVisible" width="500px">
            <el-form :model="form" label-width="80px">
                <el-form-item label="数据范围">
                    <el-radio-group v-model="form.type" style="width: 100%; text-align: left;padding: 0 40px">
                        <el-radio label="全部数据"></el-radio>
                        <el-radio label="自定义"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-show="form.type === '自定义'" label="时间范围" :label-width="formLabelWidth">
                    <el-date-picker
                            v-model="form.date"
                            type="daterange"
                            align="left"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 80%"
                            value-format="yyyy-MM-dd"
                            :picker-options="downloadOptions">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" :disabled="isLoading">取 消</el-button>
                <el-button type="primary" @click="downLoad" :loading="isLoading">{{ isLoading ? '下载中...' : '确定'}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
  import {apiAnalysisData} from "../api/analysis"
  import {export2Excel} from "../utils/excel";

  export default {
    props: {
      api: {
        type: String,
        default: () => {
          return null
        }
      },
      size: {
        type: [Number, String],
        default: () => {
          return 50
        }
      },
      filename: {
        type: String,
        default: () => {
          return null
        }
      }
    },
    data() {
      return {
        dialogFormVisible: false,
        form: {
          date: '',
          type: '全部数据'
        },
        isLoading: false,
        formLabelWidth: '80px',
        downloadOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近半年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一年',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
    },
    methods: {
      open () {
        this.dialogFormVisible = true
      },
      async downLoad () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        this.isLoading = true
        let date
        let title
        if (this.form.date && this.form.type === '自定义') {
          date = this.form.date[0] + ' ~ ' + this.form.date[1]
          title = [this.filename || 'EXCEL' + '(' + date + ')']
        } else {
          date = null
          title = [this.filename || 'EXCEL' + '(全部数据)']
        }
        let page = 1
        let total = 0
        let excelData = []
        let merges
        let header = []
        switch (this.api) {
          case 'dataCusfund':
            header = [
              {
                title: '日期',
                key: 0
              },
              {
                title: '资金权益总额',
                key: 1
              },
              {
                title: '可用资金',
                key: 2
              },
              {
                title: '追加保证金',
                key: 3
              },
              {
                title: '风险度',
                key: 4
              },
              {
                title: '上日结存（逐日盯市）',
                key: 5
              },
              {
                title: '上日结存（逐笔对冲）',
                key: 6
              },
              {
                title: '当日结存（逐日盯市）',
                key: 7
              },
              {
                title: '当日结存（逐笔对冲）',
                key: 8
              },
              {
                title: '当日总盈亏（逐日盯市）',
                key: 9
              },
              {
                title: '当日总盈亏（逐笔对冲）',
                key: 10
              },
              {
                title: '浮动盈亏（逐笔对冲）',
                key: 11
              },
              {
                title: '非货币充抵金额',
                key: 12
              },
              {
                title: '实有货币资金',
                key: 13
              },
              {
                title: '货币充抵金额',
                key: 14
              },
              {
                title: '当日总权利金',
                key: 15
              },
              {
                title: '冻结资金',
                key: 16
              },
              {
                title: '保证金占用',
                key: 17
              },
              {
                title: '当日存取合计',
                key: 18
              },
              {
                title: '当日手续费',
                key: 19
              }
            ]
            merges = ['A1:T1']
            break
          case 'dataTrddata':
            merges = ['A1:O1']
            header = [
              {
                title: '日期',
                key: 0
              },
              {
                title: '成交流水号',
                key: 1
              },
              {
                title: '品种编码',
                key: 2
              },
              {
                title: '品种合约',
                key: 3
              },
              {
                title: '买卖方向标志',
                key: 4
              },
              {
                title: '成交价',
                key: 5
              },
              {
                title: '成交数量',
                key: 6
              },
              {
                title: '成交金额',
                key: 7
              },
              {
                title: '成交时间',
                key: 8
              },
              {
                title: '开平仓标志',
                key: 9
              },
              {
                title: '投机套保标志',
                key: 10
              },
              {
                title: '平仓盈亏(逐日盯市)',
                key: 11
              },
              {
                title: '平仓盈亏(逐笔对冲)',
                key: 12
              },
              {
                title: '手续费',
                key: 13
              },
              {
                title: '成交日期',
                key: 14
              }
            ]
            break
          case 'dataHolddetails':
            merges = ['A1:O1']
            header = [
              {
                title: '日期',
                key: 0
              },
              {
                title: '品种编码',
                key: 1
              },
              {
                title: '品种合约',
                key: 2
              },
              {
                title: '成交流水号',
                key: 3
              },
              {
                title: '买卖方向标志',
                key: 4
              },
              {
                title: '投机套保标志',
                key: 5
              },
              {
                title: '持仓数量',
                key: 6
              },
              {
                title: '开仓价',
                key: 7
              },
              {
                title: '昨结算价',
                key: 8
              },
              {
                title: '今结算价',
                key: 9
              },
              {
                title: '持仓盈亏(逐日盯市)',
                key: 10
              },
              {
                title: '持仓盈亏(逐笔对冲)',
                key: 11
              },
              {
                title: '交易编码',
                key: 12
              },
              {
                title: '交易保证金',
                key: 13
              },
              {
                title: '成交日期',
                key: 14
              }
            ]
            break
        }
        do {
          const params = {
            a_id: this.$store.state.curAccountId.join(','),
            type: this.$store.state.analysisType,
            b_date: this.form.date[0] || '',
            e_date: this.form.date[1] || '',
            page: page,
            size: this.size,
            v_code: ''
          }
          await apiAnalysisData(this.api, params).then((response) => {
            total = response.data.total
            const resData = JSON.parse(JSON.stringify(response.data.data))
            this.total = response.data.total;
            let res = []
            resData.forEach(item => {
              let resIdx = []
              for (const key in item) {
                if (key !== 'id' || key !== 'account_id' || key !== 'created_at' || key !== 'updated_at') {
                  resIdx.push(item[key])
                }
              }
              res.push(resIdx)
            })
            excelData.push(...res)
            page += 1
          });
        } while (total/this.size - page > -1)
        this.isLoading = false
        this.dialogFormVisible = false
        export2Excel(title, header, excelData, this.filename, merges)
      },
    }
  }
</script>
<style lang="scss">
    .excel-download-component {
        position: absolute;
        right: 160px;
        top: -4px;
        .download-btn{
            border-color: #f1ce7c;
            background: #f1ce7c;
            color: #FFF;
        }
        &:hover .download-btn {
            border-color: rgba(241,206,124,0.9);
            background: rgba(241,206,124,0.9);
        }
    }
</style>
