export function export2Excel(title, columns, list, filename, merges) {
  require.ensure([], () => {
    const {export_json_to_excel} = require('./exportExcel');
    let header = []
    let filterVal = []
    if (!columns) {
      return;
    }
    columns.forEach(item => {
      header.push(item.title)
      filterVal.push(item.key)
    })
    const data = list.map(v => filterVal.map(j => v[j]))
    export_json_to_excel({title, header, data, filename, merges});
  })
}
